import {
  laptopMax,
  laptopMaxXL,
  mobileLargeMax,
  mobileXLargeMax,
  mobileXXLargeMax,
  tabletMax,
  tabletMin,
} from '@propertypal/shared/src/constants/mediaQueries';
import VanIllustrationGraphic from '@propertypal/shared/src/resources/bg/content-hub-van.svg?url';
import Button from '@propertypal/web-ui/src/buttons/Button';
import TextInput from '@propertypal/web-ui/src/inputs/TextInput';
import { Container as InputContainer } from '@propertypal/web-ui/src/inputs/TextInput.style';
import { UserSubMenuBox } from '@propertypal/web-ui/src/navigation/ProfileDropdown.style';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import SocialLinksContainer from '../footer/social-links/SocialLinks.style';

const MOBILE_BREAKPOINT = `max-width: 1180px`;

export const Container = styled.div<{ pn?: boolean }>`
  position: relative;

  ${({ pn }) =>
    !pn
      ? css`
          > :last-child {
            padding-bottom: 190px;
            background-image: url(${VanIllustrationGraphic.src});
            background-position-x: left;
            background-position-y: calc(100% + 1px);
            background-repeat: no-repeat;
            background-size: 1920px;
            border-bottom: 10px #7ec07c solid;
            overflow: hidden;
            width: 100%;
            @media (max-width: 1080px), (min-width: 1920px) {
              background-position-x: center;
            }
          }
        `
      : ''}
`;

export const Logo = styled.img`
  height: 40px;
`;

export const LogoPN = styled.img`
  height: 15px;
`;

export const Header = styled.header`
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: ${({ theme }) => theme.background};

  @media (${mobileXLargeMax}) {
    ${Logo} {
      height: 30px;
    }
  }
`;

export const HeaderPanel = styled.div`
  display: flex;
  align-items: center;
  height: 70px;

  @media (${MOBILE_BREAKPOINT}) {
    gap: 10px;
  }
`;

export const HeaderContent = styled.div<{ $menuOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 30px;

  @media (${MOBILE_BREAKPOINT}) {
    padding: 0 15px;
  }

  @media (${mobileXLargeMax}) {
    padding: 0 10px;
  }

  ${({ $menuOpen }) =>
    $menuOpen &&
    `
    @media (${MOBILE_BREAKPOINT}) {
      padding: 0 15px 0 0;
    }
    
    @media (${mobileXLargeMax}) {
      padding: 0 10px 0 0;
    }
    
    ${HeaderPanel} {
      flex: 1;
    }
  `}
`;

export const LinksRow = styled.div`
  display: flex;
  height: 100%;

  @media (${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

export const NavBox = styled(Link)<{ $active?: boolean; $current?: boolean }>`
  position: relative;
  padding: 0 20px;
  display: block;
  height: 70px;
  color: ${({ theme, $active, $current }) => ($active || $current ? theme.primary : theme.textDark)};
  background-color: ${({ $active }) => ($active ? '#222' : 'transparent')};
  font-size: 16px;
  line-height: 70px;
  font-weight: ${({ $current }) => ($current ? 'bold' : 'normal')};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const NavSubBox = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  background-color: #222;
  width: 280px;
  padding: 12px 24px;
  border-radius: 0 0 6px 6px;
  z-index: 2;
`;

export const NavSubLink = styled(Link)<{ $current: boolean }>`
  display: block;
  text-align: left;
  line-height: 40px;
  color: ${({ theme, $current }) => ($current ? theme.primary : theme.white)};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const SearchInput = styled.div<{ menuOpen: boolean }>`
  display: flex;
  padding: 12px 22px;
  border-radius: 22px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
  border: solid 1px #f5f5f5;
  align-items: center;
  flex: 1;
  margin-left: 15px;
  height: 46px;

  input {
    background-color: ${({ theme }) => theme.background};
    width: 100%;
    border: none;
    margin-left: 5px;
    color: ${({ theme }) => theme.textDark};
    font-size: 14px;
  }

  @media (${mobileXXLargeMax}) {
    ${({ menuOpen }) =>
      !menuOpen
        ? css`
            display: none;
          `
        : ''}
    padding: 12px 10px;
    margin-left: 5px;

    input::placeholder {
      font-size: 12px;
    }
  }
`;

export const ProfileContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 0 12px;

  ${UserSubMenuBox} {
    background-color: #222;
    border-radius: 0 0 6px 6px;
    top: 70px;
    right: 12px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }

  @media (${mobileXLargeMax}) {
    padding: 0;

    p {
      font-size: 10px;
    }

    ${UserSubMenuBox} {
      right: 0px;
    }
  }
`;

export const MenuButton = styled.button`
  padding: 0 0 0 15px;
  width: 52px;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textDark};

  span {
    font-size: 14px;
    font-weight: 600;
  }

  @media (${MOBILE_BREAKPOINT}) {
    display: flex;
  }

  @media (${mobileXLargeMax}) {
    padding: 0;
    width: 37px;

    span {
      font-size: 10px;
    }
  }
`;

export const Menu = styled.div`
  display: none;
  flex-direction: column;
  background-color: #000;
  width: 100%;
  height: calc(100% - 70px);
  position: fixed;
  top: 70px;
  left: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 5;

  @media (${MOBILE_BREAKPOINT}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const MenuLink = styled(Link)<{ $current?: boolean }>`
  display: block;
  background-color: #333;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme, $current }) => ($current ? theme.primary : theme.white)};
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.textMid};
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.textMid};
`;

export const SubMenuLink = styled(Link)<{ $current?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme, $current }) => ($current ? theme.primary : theme.white)};
  padding: 15px;
  transition: color 150ms linear;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const Footer = styled.footer`
  background-color: #353535;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;

  @media (${MOBILE_BREAKPOINT}) {
    padding: 15px;
  }

  @media (${laptopMax}) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const MailBox = styled.form`
  background-color: ${({ theme }) => theme.background};
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;

  h5 {
    font-size: 22px;
    color: ${({ theme }) => theme.textDark};
  }

  & > p {
    font-size: 16px;
    color: ${({ theme }) => theme.textDark};
  }

  ${InputContainer} {
    margin: 15px 0;
  }

  @media (${laptopMax}) and (${tabletMin}) {
    display: flex;
    width: 100%;
    max-width: none;
    gap: 10px;
    align-items: center;

    ${InputContainer} {
      flex-basis: 460px;
      margin: 0;
    }

    button {
      max-width: 200px;
      height: 56px;
    }
  }

  @media (${tabletMax}) {
    width: 100%;
    max-width: none;
  }
`;

export const MailBoxInput = styled(TextInput)`
  input {
    border-radius: 6px;

    ${({ error }) =>
      error
        ? css`
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          `
        : ''}
  }
`;

export const ErrorText = styled.h4`
  text-align: center;
  margin-top: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.red};
`;

export const SuccessText = styled.h4`
  text-align: center;
  margin-top: 30px;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`;

export const NewsLetterIconBox = styled.div`
  display: flex;
  gap: 30px;

  & > div {
    flex: 1;
  }

  @media (${mobileLargeMax}) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 140px;
  padding-top: 30px;

  h6 {
    color: ${({ theme }) => theme.backgroundDark};
    font-size: 14px;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: ${({ theme }) => theme.white};
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  ${SocialLinksContainer} {
    margin-top: 10px;
  }

  @media (${laptopMaxXL}) {
    gap: 60px;
  }

  @media (${laptopMax}) {
    max-width: 800px;
    justify-content: space-between;
  }

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const HomeLink = styled(Link)<{ $pn?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 10px;
  color: ${({ theme }) => theme.white};
  margin-top: 10px;

  img {
    height: ${({ $pn }) => ($pn ? 25 : 40)}px;
  }
`;

export const CopyrightBox = styled.div`
  background-color: #222;
  padding: 25px 15px;

  p {
    color: ${({ theme }) => theme.white};
    font-size: 14px;
    text-align: center;

    a:hover {
      text-decoration: underline;
    }
  }
`;

export const IllustrationContainer = styled.div`
  border-bottom: 10px solid #7ec07c;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 285px;
`;

export const VanIllustration = styled.div`
  width: 1920px;
  height: 275px;
  background-image: url(${VanIllustrationGraphic.src});
  background-repeat: no-repeat;
  background-position: left;
  margin: 0 auto;
`;

export const SubscribeBtn = styled(Button)`
  border-radius: 8px;
  @media (${MOBILE_BREAKPOINT}) {
    span {
      display: none;
    }
    width: 132px;
  }
`;

export const MenuLinks = styled.div``;
