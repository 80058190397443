import { largeTabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import platform from '@propertypal/shared/src/utils/platform';
import Link from 'next/link';
import styled from 'styled-components';

export const LogOutLinkButton = styled.button<{ $active?: boolean }>`
  background: none;
  border: none;
  padding: 16px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: right;
  color: ${({ theme }) => theme.white};
  font-size: 16px;
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.primary};
  }

  ${(props) =>
    props.$active &&
    `
    background-color: ${props.theme.darkGreyTranslucent};    
  `}
`;

export const UserLinkBox = styled(Link)<{ $active?: boolean; $color: string }>`
  padding: 16px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: right;
  color: ${({ $color }) => $color};
  font-weight: ${platform.select('bold', 'normal')};
  white-space: nowrap;

  &:hover {
    color: ${(props) => props.theme.primary};
  }

  ${(props) =>
    props.$active &&
    `
    background-color: ${props.theme.darkGreyTranslucent};

    & + ul {
      display: block;
    }
  `}
`;

export const UserSubMenuBox = styled.ul<{ $loggedIn: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: ${(props) => (props.$loggedIn ? '200' : '100')}px;
  background-color: ${(props) => props.theme.darkGreyTranslucent};
  z-index: 60;
  border-radius: 6px 0 6px 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  right: 20px;
  top: 57px;

  li {
    padding-left: 20px;
  }

  @media (${largeTabletMax}) {
    right: 10px;
  }
`;

export const HeaderIcon = styled.div`
  justify-content: center;
  display: inline-flex;
  height: 18px;
`;

export const HeaderButton = styled.button<{ $active?: boolean }>`
  position: relative;
  background: none;
  border: none;
  list-style: none;
  padding: 0 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${platform.select('column', 'row-reverse')};
  gap: ${platform.select('0', '10px')};
  height: 100%;
  text-align: center;

  p {
    white-space: nowrap;
    margin-top: 2px;
    font-size: ${platform.select('12px', '14px')};
  }

  &:hover p {
    color: ${(props) => props.theme.primary};
  }

  &:hover svg {
    color: ${(props) => props.theme.primary};
  }

  @media (max-width: 350px) {
    padding: 0 6px;
  }

  ${(props) =>
    props.$active &&
    `
    & + ul {
      display: flex;
      flex-direction: column;
    } 
  `}
`;
